<template>
  <div class="ordering-platform">
    <div class="not-partner" v-show="!Partnercloud">
      <svg class="icon" aria-hidden="true">
        <use href="#icon-weichuli"></use>
      </svg>
      <!-- 该功能仅供合作伙伴用户使用 -->
      <p>{{ $t("label.partnerCloud.orderingPlatform.partnerUsers") }}</p>
    </div>
    <div class="not-partner" v-show="noPrice">
      <svg class="icon" aria-hidden="true">
        <use href="#icon-weichuli"></use>
      </svg>
      <!-- 无可用价格手册，请联系上游管理员 -->
      <p>{{ $t("label.partnerCloud.orderingPlatform.administrator") }}</p>
    </div>
    <div class="platform" v-show="Partnercloud && !noPrice">
      <div class="title">
        <div class="name">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-pay"></use>
          </svg>
          <!-- 下单平台 -->
          <span class="word">{{ $t("label.ordering.platform") }}</span>
          <!-- 币种： -->
          <span class="currency"
            >{{ $t("label.partnerCloud.orderingPlatform.currency")
            }}{{ currency }}</span
          >
        </div>
        <!-- 购物车 -->
        <div class="button" @click="linkCar">
          <div class="icon-button">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-a-ShoppingCart1"></use>
            </svg>
            <div class="car-count" v-show="carTotal > 0">{{ carTotal }}</div>
          </div>
          <span>{{
            $t("label.partnerCloud.orderingPlatform.shoppingCart")
          }}</span>
        </div>
      </div>
      <div class="body">
        <!-- 树形列表 -->
        <div class="left">
          <!-- 价格手册下拉框 -->
          <div class="select" v-show="options.length > 1">
            <!-- 价格手册 -->
            <span>{{ $t("lable.opppdt.selPbk.pricebook") }}</span>
            <!-- 请选择 -->
            <el-select
              v-model="value"
              :placeholder="$t('label.emailtocloudcc.select1')"
              @change="changePriceBook"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="tree"
            :style="{
              height: options.length > 1 ? 'calc(100% - 50px)' : '100%',
            }"
          >
            <div class="tree-body">
              <el-tree
                ref="treeRef"
                :data="data"
                :props="defaultProps"
                @node-click="handleNodeClick"
                node-key="id"
                :current-node-key="currentNodekey"
                highlight-current
              ></el-tree>
            </div>
          </div>
        </div>
        <!-- 产品列表 -->
        <div class="right">
          <!-- 头部功能 -->
          <div class="title-fuc">
            <!-- 将产品添加至购物车，并在购物车中调整数量并查看价格 -->
            <!-- <div class="describe">
              {{ $t("label.partnerCloud.orderingPlatform.djustQuantity") }}
            </div> -->
            <!-- 搜索产品 -->
            <el-input
              prefix-icon="el-icon-search"
              v-model="productWord"
              :placeholder="$t('label.partnerCloud.orderingPlatform.search')"
            ></el-input>
          </div>
          <!-- 产品列表 -->
          <div class="product product-title">
            <div
              class="item"
              v-for="(item, index) in titleData"
              :key="index"
              :style="{ width: item.width ? item.width : '0px' }"
            >
              <span>{{ $t(item.title) }}</span>
            </div>
          </div>
          <div
            class="product-list"
            v-loading="productLoading"
            :class="listDate.length > 0 ? 'product-list-line' : ''"
            :style="{ overflow: listDate.length > 0 ? 'overlay' : '' }"
          >
            <div class="product-body" v-show="listDate.length > 0">
              <div
                class="product list"
                v-for="(item, index) in listDate"
                :key="index"
              >
                <div class="item picture">
                  <el-image
                    :src="oldImgSrcBefore + item.imageid + oldImgSrcAfter"
                    alt=""
                    v-if="item.imageid"
                    :preview-src-list="[
                      oldImgSrcBefore + item.imageid + oldImgSrcAfter,
                    ]"
                  ></el-image>
                </div>
                <div class="item name">{{ item.name }}</div>
                <div class="item cpdm">{{ item.cpdm }}</div>
                <div class="item listprice">
                  <el-popover
                    @show="showDiscount(item.discountschedule)"
                    placement="bottom"
                    width="700"
                    trigger="hover"
                    popper-class="popper_report"
                  >
                    <div class="bodyContent">
                      <!-- 头部 -->
                      <div class="contentHeader">
                        <div>
                          <svg class="icon" aria-hidden="true">
                            <use href="#icon-a-DiscountProgram"></use>
                          </svg>
                        </div>
                        <!-- 折扣计划 -->
                        <div>
                          {{
                            $t("label.partnerCloud.orderingPlatform.discounts")
                          }}
                        </div>
                      </div>
                      <div class="borderTop"></div>
                      <!-- 主体 -->
                      <div class="contentBody" v-loading="discountLoading">
                        <!-- 配置 -->
                        <div
                          class="option"
                          style="justify-content: space-between"
                        >
                          <div class="option">
                            <!-- 折扣计划名称： -->
                            <div>
                              <span>{{
                                $t(
                                  "label.partnerCloud.orderingPlatform.discountPlan"
                                )
                              }}</span>
                            </div>
                            <div>
                              {{ discountName }}
                            </div>
                          </div>
                          <div class="option">
                            <div>
                              <!-- 折扣单位： -->
                              <span
                                >{{
                                  $t(
                                    "label.partnerCloud.orderingPlatform.discountUnit"
                                  )
                                }}{{ discountUnit }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- 表格 -->
                        <div class="dataTable">
                          <el-table
                            border
                            max-height="250"
                            :empty-text="$t('label.weixin.nomore')"
                            :data="listsData"
                          >
                            <el-table-column
                              v-for="(item, index) in headList"
                              :key="index"
                              :align="item.apiname == 'name' ? 'left' : 'right'"
                              sortable
                              :label="item.label"
                              :prop="item.apiname"
                            >
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                    <!-- 折扣 -->
                    <div slot="reference" v-show="item.discountschedule">
                      <svg class="icon discount-small" aria-hidden="true">
                        <use href="#icon-discount"></use>
                      </svg>
                    </div>
                  </el-popover>
                  {{ thousands(item.listprice) }}
                </div>
                <!-- 没有加入购物车 -->
                <div
                  class="item discount"
                  @click="showDialog(item)"
                  v-if="item.isCar == false"
                >
                  <div
                    class="add-car"
                    @mouseover="changeButtonColorSelect(index)"
                    @mouseleave="changeButtonColorNoSelect(index)"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      v-show="currentIndex != index"
                    >
                      <use href="#icon-a-ShoppingCart2"></use>
                    </svg>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      v-show="currentIndex == index"
                    >
                      <use href="#icon-a-ShoppingCart"></use>
                    </svg>
                    {{ $t("c81") }}
                  </div>
                </div>
                <!-- 已加入购物车 -->
                <div class="item discount" v-if="item.isCar == true">
                  <div class="add-cars">
                    <div class="car-button reduce" @click="reduceNum(item)">
                      -
                    </div>
                    <input
                      class="input"
                      type="text"
                      v-model="item.carNum"
                      @blur="hiddenInput(item)"
                      :id="item.id"
                      onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    />
                    <div class="car-button add" @click="addNum(item)">+</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-data" v-show="listDate.length == 0">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-weichuli"></use>
              </svg>
              <!-- 无可用产品 -->
              <p>{{ $t("label.partnerCloud.orderingPlatform.unavailable") }}</p>
            </div>
          </div>
          <div class="page" v-show="listDate.length > 0">
            <el-pagination
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :current-page="currentPage"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="pageNumber"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div>
      <el-dialog
        :visible.sync="dialogVisible"
        top="15%"
        width="75%"
        :close-on-click-modal="false"
      >
        <div slot="title" class="titlePosition">
          <div style="display: flex; align-items: center">
            <!-- 图片 -->
            <svg class="icon" aria-hidden="true">
              <use href="#icon-config"></use>
            </svg>
            <!-- title -->
            <div>
              <div class="firTitle">{{ productName }}</div>
              <!-- 配置产品 -->
              <div class="secTitle">
                {{ $t("label.partnerCloud.orderingPlatform.configuration") }}
              </div>
            </div>
          </div>
        </div>
        <car-config-dialog
          ref="configSave"
          :carConfig="carConfig"
          :pricebookid="value"
          @closeDialog="closeDialog"
        ></car-config-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmDel" type="primary" size="mini">
            <!-- 保存 -->
            {{ $t("label.save") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 价格手册切换提示弹框 -->
    <!-- 提示 -->
    <el-dialog
      :visible.sync="priceBookDialog"
      :title="$t('label.emailtocloudcc.tip')"
      width="30%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <!-- 切换{alias}会清空购物车，是否确认？ -->
      <div>
        {{ $t("label.partnerCloud.orderingPlatform.sure", { alias: alias }) }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="changeBook">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 订货平台
 */
import carConfigDialog from "@/views/orderingPlatform/components/carConfigDialog.vue";
import {
  showProductConfigList,
  showProductList,
  queryPricingTable,
  getProductSort,
  SaveShoppingCart,
  emptyShoppingCart,
  showDiscountSchedule,
  getProductNum,
  deleteShoppingCart,
} from "./api.js";
export default {
  data() {
    return {
      //是否点击全部产品
      isClick: false,
      //购物车产品总数
      carTotal: 0,
      //价格手册别名称
      alias: "",
      //币种
      currency: this.$store.state.currencyCode,
      //折扣弹框loading
      discountLoading: false,
      //折扣单位
      discountUnit: this.$i18n.t("label.tabpage.nothingz"), //无
      oldImgSrcBefore: `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=`,
      oldImgSrcAfter: `&binding=${this.$CCDK.CCToken.getToken()}`,
      //购物车配置页面
      carConfig: {},
      //最大页数
      maxPage: 0,
      //总页数
      totalCount: 0,
      //当前页数
      currentPage: 1,
      //每页数量
      pageNumber: 25,
      //无价格手册提示
      noPrice: false,
      //产品列表loading
      productLoading: false,
      //价格手册提示框
      priceBookDialog: false,
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      productName: "Q-00079",
      // 是否展示弹窗
      dialogVisible: false,
      // 折扣计划名称
      discountName: this.$i18n.t("label.tabpage.nothingz"), //无
      //产品表头数据        //产品代码 产品名称 图片 单价 操作
      titleData: [
        { title: "label.file.img", width: "142px" },
        { title: "label.product.name", width: "calc(100% - 717px)" },
        { title: "label.product.code", width: "217px" },
        {
          title: "label.partnerCloud.orderingPlatform.unitPrice",
          width: "130px",
        },
        { title: "label.import.index.operation", width: "228px" },
      ],
      listDate: [],
      //搜索产品关键字
      productWord: "",
      listsData: [],
      headList: [],
      //价格手册数据
      options: [],
      //价格手册当前选中值
      value: "",
      //价格手册之前选中值
      beforeValue: "",
      //树形结构数据
      data: [],
      //默认选中的节点树
      currentNodekey: "",
      defaultProps: {
        children: "childList",
        label: "codevalue",
      },
      //树当前选中值
      treeValue: "",
      //购物车产品id数组
      productIdArr: [],
      //购物车产品数量数组
      productCarArr: [],
      //当前加入购物车按钮下标
      currentIndex: -1,
    };
  },
  components: {
    carConfigDialog,
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.ordering.platform"));
    if (this.Partnercloud) {
      this.queryPricingTable();
    }
  },
  watch: {
    productWord(val) {
      this.showProductList(this.treeValue, val);
    },
  },
  methods: {
    //隐藏input
    hiddenInput(item) {
      if (item.carNum == "") {
        this.$message.warning(this.$i18n.t("label.numberscannotnull"));
        var inputItem = document.getElementById(item.id);
        inputItem.value = "";
        inputItem.focus();
        return false;
      }
      if (this.carTotal >= 100) {
        this.$message.warning(this.$i18n.t("c34"));
        let inputItem = document.getElementById(item.id);
        inputItem.value = "";
        inputItem.focus();
        return false;
      }
      this.showDialog(item);
    },
    // 设置千分位样式  dj
    thousands(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    //切换当前页数
    handleCurrentChange(page) {
      this.currentPage = page;
      this.showProductList(this.treeValue);
    },
    //数量
    handleSizeChange(number) {
      this.pageNumber = number;
      this.showProductList(this.treeValue);
    },
    //购物车产品减少数量
    reduceNum(item) {
      //如果数量等于1，再减少变为添加购物车按钮
      if (item.carNum == 1) {
        this.deleteProductCar(item.id);
      } else {
        item.carNum--;
        this.showDialog(item);
      }
    },
    //购物车产品添加数量
    addNum(item) {
      if (this.carTotal >= 100) {
        this.$message.warning(this.$i18n.t("c34"));
        return false;
      }
      if (item.configurationtype != "必须配置") {
        item.carNum++;
      }
      this.showDialog(item);
    },
    //更改按钮选中与非选中状态的颜色
    changeButtonColorSelect(index) {
      this.currentIndex = index;
    },
    changeButtonColorNoSelect() {
      this.currentIndex = -1;
    },
    async deleteProductCar(id) {
      let result = await deleteShoppingCart({ prdcuctlist: [id] });
      if (result.result) {
        // this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
        let index = this.listDate.findIndex((res) => res.id == id);
        if (index >= 0) {
          this.listDate[index].isCar = false;
        }
        this.getProductNum();
      }
    },
    //关闭产品配置弹框
    closeDialog() {
      this.dialogVisible = false;
      this.getProductNum();
    },
    //查询购物车父产品数量
    async getProductNum() {
      let result = await getProductNum();
      if (result.result) {
        this.productCarArr = [];
        this.productIdArr = [];
        this.carTotal = Number(result.data.num);
        this.productCarArr = result.data.productlist;
        result.data.productlist.forEach((res) => {
          this.productIdArr.push(res.productid);
        });
        this.listDate.forEach((res, index) => {
          if (this.productIdArr.indexOf(res.id) >= 0) {
            let obj = res;
            obj.isCar = true;
            this.$set(this.listDate, index, obj);
          } else {
            let obj = res;
            obj.isCar = false;
            this.$set(this.listDate, index, obj);
          }
        });
        this.productCarArr.forEach((carItem) => {
          this.listDate.forEach((listItem, listIndex) => {
            if (carItem.productid == listItem.id) {
              let obj = listItem;
              obj.carNum = Number(carItem.productnum).toFixed();
              this.$set(this.listDate, listIndex, obj);
            }
          });
        });
      }
    },
    //展示折扣弹框
    async showDiscount(discountschedule) {
      if (!discountschedule) {
        this.discountUnit = this.$i18n.t("label.tabpage.nothingz"); //无
        this.discountName = this.$i18n.t("label.tabpage.nothingz"); //无
        this.headList = [];
        this.listsData = [];
        return false;
      }
      this.discountLoading = true;
      let res = await showDiscountSchedule({
        discountscheduleid: discountschedule,
      });
      if (res.result) {
        this.discountLoading = false;
        this.discountUnit = res.data.discountunit;
        this.discountName = res.data.schedulename;
        this.headList = res.data.tableLabel;
        res.data.schedulelist.forEach((item) => {
          item.discount = this.thousands(item.discount);
          item.upperbound = this.thousands(item.upperbound);
          item.discountamount =
            res.data.discountunit == "Percent(%)" ||
            res.data.discountunit == "百分比(%)"
              ? Number(item.discount)
              : Number(item.discountamount).toFixed(2);
          item.discountamount = this.thousands(item.discountamount);
        });
        this.listsData = res.data.schedulelist;
      }
    },
    //确认切换价格手册
    async changeBook() {
      let result = await emptyShoppingCart();
      if (result.result) {
        this.getProductNum();
      }
      this.beforeValue = this.value;
      this.productWord = "";
      this.showProductList("");
      this.priceBookDialog = false;
    },
    //取消切换价格手册
    cancel() {
      this.value = this.beforeValue;
      this.priceBookDialog = false;
    },
    //切换价格手册
    changePriceBook() {
      this.priceBookDialog = true;
    },
    //获取全部产品
    getAllProduct() {
      this.isClick = true;
      this.treeValue = "";
      this.showProductList("", this.productWord);
    },
    //cpq获取产品列表接口
    async showProductList(cpxl) {
      this.productLoading = true;
      let option = {
        cpxl: cpxl,
        pricebookid: this.value,
        page: this.currentPage,
        pageSize: this.pageNumber,
        queryConditions: this.productWord,
      };
      let result = await showProductList(option);
      if (result.result) {
        result.data.productlist.forEach((res) => {
          if (this.productIdArr.indexOf(res.id) >= 0) {
            res.isCar = true;
          } else {
            res.isCar = false;
          }
        });
        this.listDate = result.data.productlist;
        this.productCarArr.forEach((carItem) => {
          this.listDate.forEach((listItem, listIndex) => {
            if (carItem.productid == listItem.id) {
              let obj = listItem;
              obj.carNum = Number(carItem.productnum).toFixed();
              this.$set(this.listDate, listIndex, obj);
            }
          });
        });
        this.totalCount = result.data.totalSize;
        this.maxPage = Math.ceil(this.totalCount / this.pageNumber);
        this.productLoading = false;
      }
    },
    //获取树形列表数据
    async getProductSort() {
      let result = await getProductSort();
      if (result.result) {
        this.isClick = true;
        result.data.unshift({
          childList: null,
          codevalue: this.$i18n.t(
            "label.partnerCloud.orderingPlatform.allProducts"
          ),
          fatherid: "",
          id: "",
        });
        result.data.push({
          childList: null,
          codevalue: this.$i18n.t("c121"),
          fatherid: "",
          id: "nosort",
        });
        this.data = result.data;
        this.currentNodekey = this.data[0].id;
        this.$nextTick(() => {
          this.$refs.treeRef.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
        });
      }
    },
    //获取全部价格手册
    async queryPricingTable() {
      let result = await queryPricingTable();
      if (result.result) {
        if (result.data.pricelist.length > 0) {
          this.options = result.data.pricelist;
          this.value = result.data.pricelist[0].id;
          this.beforeValue = result.data.pricelist[0].id;
          this.alias = result.data.label;
          this.getProductSort();
          this.getProductNum();
          this.showProductList("");
        } else {
          this.noPrice = true;
        }
      }
    },
    //查询选中的产品的产品选项信息
    async productionOptionInfo(item) {
      let carNum = item.carNum == undefined ? "1" : String(item.carNum);
      let option = {
        pricebook: this.value,
        productJson: JSON.stringify([{ product: item.id, quantity: carNum }]),
      };
      let result = await SaveShoppingCart(option);
      if (result.result) {
        this.getProductNum();
        // this.$message.success(this.$i18n.t("vue_label_notice_add_success")); //添加成功
      }
    },
    confirmDel() {
      this.$refs.configSave.configSave();
    },
    // 展示配置购物车弹窗
    async showDialog(item) {
      if (this.carTotal >= 100) {
        this.$message.warning(this.$i18n.t("c34"));
        return false;
      }
      if (item.configurationtype == "必须配置") {
        //必须配置暂不替换多语言
        this.carConfig = item;
        let option = {
          productid: item.id,
          currency: item.currency,
          pricebookid: this.value,
        };
        // eslint-disable-next-line no-unused-vars
        let result = await showProductConfigList(option);
        this.dialogVisible = true;
      } else {
        this.productionOptionInfo(item);
      }
    },
    // 树状点击事件
    handleNodeClick(data) {
      this.treeValue =
        data.id == "" ? "" : data.id == "nosort" ? "nosort" : data.codevalue;
      this.showProductList(this.treeValue, this.productWord);
    },
    //跳转购物车
    linkCar() {
      let priceBookName = "";
      this.options.map((item) => {
        if (item.id == this.value) {
          priceBookName = item.name;
        }
      });
      this.$router.push({
        path: "/orderingShoppingCar",
        query: {
          priceBookName,
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .caret-wrapper {
  display: none;
}
::v-deep .cell a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dataOrign {
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  margin: 0px -10px;
}
.productTitle {
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  font-weight: 600;
}
.partTitle {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  padding-left: 10px;
}
.titlePosition {
  display: flex;
  justify-content: end;
  align-items: center;
}
.firTitle {
  color: #969696;
  font-size: 12px;
}
.secTitle {
  font-size: 14px;
  font-weight: 700;
  color: black;
}
.discountPopover {
  width: 60%;
  height: 50%;
}
.bodyContent {
  border-radius: 3px;
}
.contentHeader {
  color: black;
  display: flex;
  align-items: center;
  padding: 2px 20px;
  padding-bottom: 11px;
}

.borderTop {
  border-top: 1px solid #dcdfe6;
  margin: 0px -9px;
}

.contentHeader .icon,
.titlePosition .icon {
  width: 24px;
  height: 24px;
}
.contentHeader > div {
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
}
.contentBody {
  padding: 20px;
  ::v-deep .el-input__inner {
    width: 170px;
    height: 30px;
    margin-left: 15px;
  }
  // ::v-deep .el-input__icon {
  //   line-height: 30px;
  // }
}
.option {
  display: flex;
  align-items: center;
}
::v-deep .el-icon-question:before {
  color: #006dcc;
}
.contentBody ::v-deep .el-input__inner {
  width: 170px;
  height: 30px;
  margin-left: 15px;
}
// ::v-deep .el-input__icon {
//   line-height: 30px;
// }
.dataTable {
  margin-top: 20px;
  max-height: 250px;
}
.btnGroup {
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 10px;
}
.button {
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  cursor: pointer;
  background: white;
  color: #fe8a14;
  display: flex;
  align-items: center;
  .icon-button {
    position: relative;
    display: inline-block;
    .icon {
      width: 28px;
      height: 26px;
    }
  }
  span {
    margin-left: 10px;
  }
  .car-count {
    position: absolute;
    right: -6px;
    top: -3px;
    width: 16px;
    height: 16px;
    text-align: center;
    color: white;
    line-height: 16px;
    border-radius: 50%;
    background: #fe8a14;
    font-size: 12px;
    box-sizing: initial;
    border: 2px solid;
  }
}
.cancel {
  height: 30px;
  // line-height: 30px;
  padding: 5px 20px;
  margin-right: 10px;
}
.save {
  background: #006dcc;
  color: white;
}
.ordering-platform {
  padding: 10px;
  height: 100%;
  .treeActive {
    background: #f0f7ff;
  }
  .not-partner {
    text-align: center;
    .icon {
      // width: 450px;
      font-size: 300px;
      display: inline-block;
      margin: 120px 0 15px;
    }
    p {
      margin-right: 70px;
      font-weight: bold;
    }
  }
  .platform {
    width: 100%;
    height: 100%;
    user-select: none;
    // background-color: #f5f5f5;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-radius: 2px 2px 3px 3px;
      border: 1px solid #dddbda;
      .name {
        display: flex;
        align-items: center;
        .word {
          margin-left: 5px;
          font-size: 15px;
          font-weight: bold;
        }
        .currency {
          margin-left: 15px;
          font-size: 12px;
        }
        .icon {
          font-size: 30px;
        }
      }
    }
    .body {
      width: 100%;
      height: calc(100% - 67px);
      background-color: #fff;
      margin-top: 10px;
      .left {
        float: left;
        height: 100%;
        width: 20%;
        border-right: 1px solid #dcdfe6;
        .select {
          padding: 0 5px;
          height: 50px;
          border-bottom: 1px solid #dcdfe6;
          line-height: 50px;
          span {
            margin-right: 5px;
          }
          ::v-deep .el-select {
            width: calc(100% - 90px);
          }
          ::v-deep .el-input__inner {
            height: 30px;
          }
        }
        .tree {
          height: calc(100% - 50px);
          overflow: auto;
          .tree-body {
            width: 100%;
            margin-top: 10px;
          }
          .el-tree {
            color: #2c3e50;
          }
        }
      }
      .right {
        position: relative;
        height: 100%;
        float: right;
        width: 80%;
        padding: 10px 10px 0 0;
        .page {
          user-select: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50px;
          border-top: 1px solid #dddfe8;
          text-align: center;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
        .title-fuc {
          margin-bottom: 9px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          ::v-deep .el-input {
            width: 250px;
          }
          ::v-deep .el-input__inner {
            height: 30px;
          }
          ::v-deep .el-input__icon {
            line-height: 35px;
          }
        }
        .product-title {
          background: #fafaf9;
          font-weight: 600;
          border: 1px solid #dcdfe6;
          border-left: none;
          color: #393939;
        }
        .product {
          line-height: 40px;
          height: 40px;
          overflow: hidden;
          .item {
            // text-align: center;
            float: left;
            width: calc(100% / 4 - 40px);
            border-right: 1px solid #dcdfe6;
            height: 100%;
            padding: 0 10px;
          }
          .discount-small {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          ::v-deep.el-popover__reference {
            display: inline-block;
          }
          ::v-deep.el-popover__reference-wrapper {
            display: inline-block;
          }
          .discount {
            align-items: center;
          }
          .discount .icon {
            font-size: 25px;
            cursor: pointer;
          }
          ::v-deep .picture .el-image img {
            width: 60px;
          }
          .item:nth-child(1) {
            width: 142px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: stretch;
            justify-content: center;
            line-height: 30px;
          }
          .item:last-child {
            text-align: center;
            width: 228px;
            border: none;
            display: flex;
            justify-content: center;
          }
          .item.name {
            width: calc(100% - 717px);
          }
          .item.listprice {
            width: 130px;
            text-align: right;
          }
          .item.cpdm {
            width: 217px;
          }
          .item .add-car {
            padding: 8px 12px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            height: 32px;
            color: #080707;
            cursor: pointer;
            .icon {
              width: 14px;
              height: 13px;
              margin-right: 5px;
            }
          }
          .add-car:hover {
            border: 1px solid #2d6cfc;
            color: #2d6cfc;
          }
          .add-cars {
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            height: 32px;
            color: #080707;
            overflow: hidden;
            .car-button {
              width: 28px;
              background: #fafaf9;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .reduce {
              border-right: 1px solid #e1e1e1;
              //  margin-right: 20px;
            }
            .reduce:hover {
              border: 1px solid #2d6cfc;
              color: #2d6cfc;
            }
            .add {
              border-left: 1px solid #e1e1e1;
              //  margin-left: 20px;
            }
            .add:hover {
              border: 1px solid #2d6cfc;
              color: #2d6cfc;
            }
          }
        }
        .product-list-line {
          border-bottom: 1px solid #dcdfe6;
        }
        .product-list {
          max-height: calc(100% - 130px);
          .no-data {
            text-align: center;
            .icon {
              font-size: 300px;
              display: inline-block;
              margin: 50px 0 0;
            }
            p {
              margin-right: 70px;
              font-weight: bold;
              margin-top: -40px;
            }
          }
          .product-body {
            width: 100%;

            .list {
              border-right: 1px solid #e1e1e1;
              height: 114px;
              border-bottom: 1px solid #e1e1e1;
              line-height: 114px;
              color: #2c3e50;
              .item {
                border: none;
                // display: flex;
              }
            }
            .list:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
.input {
  outline: none;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  padding-left: 5px;
  box-sizing: border-box;
  text-align: center;
  border: none;
  width: 60px;
}
</style>
