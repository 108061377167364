<template>
  <div class="car-config-dialog" v-loading="loading">
    <div class="object_box">
      <div class="wrap">
        <div class="navigationss">
          <navigation
            v-if="isNavbar"
            :navList="navList"
            :navName="navName"
            :isactive="isactivee"
            @jump="jump"
          />
        </div>
        <!-- 表格 -->
        <div class="configTable">
          <div
            class="quotation-table"
            v-for="(product, index) in productList"
            :key="index"
          >
            <div class="goods">
              <!-- 价格手册条目产品名称 -->
              <div class="jgsc">
                <span>{{ productTitle }}</span>
              </div>
              <!-- tab栏 -->
              <div class="productTab" v-if="!isProduct">
                <div class="configTab">
                  <!-- tab栏名称 -->
                  <span
                    v-for="configTab in product.functionctategory"
                    :key="configTab.id"
                    @click="conTab(configTab)"
                    :class="{ active: isactive == configTab.id }"
                  >
                    {{ configTab.category }}
                  </span>
                </div>
                <div
                  class="twoTitle"
                  v-for="feature in featuree"
                  :key="feature.id"
                >
                  <!-- 产品分类名称 -->
                  <div class="goodstype">{{ feature.featurename }}</div>
                  <div class="Navtable">
                    <ul>
                      <li class="checkbox-li">
                        <el-checkbox
                          v-model="feature.checkAll"
                          v-if="feature.ischeckAll"
                          :key="feature.featurename"
                        ></el-checkbox>
                      </li>
                      <li
                        :style="{ width: attr.width }"
                        v-for="(attr, idx) in tableAttr"
                        :key="idx"
                      >
                        <el-tooltip
                          class="item"
                          :content="attr.label"
                          placement="top"
                        >
                          <span class="tootip-span">
                            {{ attr.label }}
                          </span>
                        </el-tooltip>
                      </li>
                    </ul>
                    <quotation-line
                      v-for="lineData in feature.option"
                      :checkbox="lineData.check"
                      :line-data="lineData"
                      :tableAttr="tableAttr"
                      :ischeck="lineData.isCheck"
                      :checkAll="feature.checkAll"
                      :isDisabled="lineData.selectedd"
                      :selectedd="lineData.selectedd"
                      :key="lineData.category"
                      :editLineId="editLineId"
                      :editField="editField"
                      :ischecked="ischecked"
                      :editLineIndex="editLineIndex"
                      isConfigCheck="true"
                      @modify="modify"
                      @checkLine="checkLine"
                      @edit="edit"
                    >
                      <el-radio
                        class="radios"
                        slot="firstColumn"
                        v-model="radio"
                        v-if="lineData.radioCheck"
                        :label="lineData.optionid"
                        >{{ kong }}</el-radio
                      >
                    </quotation-line>
                  </div>
                </div>
              </div>
            </div>
            <div class="goodstypee" v-if="isProduct">
              <!-- 目前该产品没有产品选项需要配置 -->
              {{ $t("label.partnerCloud.orderingPlatform.noProduct") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigation from "@/views/quotation/components/navigation.vue";
import QuotationLine from "@/views/quotation/components/Line.vue";
import * as quotationApi from "../apis";
import { SaveShoppingCart } from "../api";
export default {
  name: "configQuotation",
  components: { navigation, QuotationLine },
  props: {
    carConfig: {
      type: Object,
      default: () => {},
    },
    //价格手册
    pricebookid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      config: true,
      check: true, //复选框
      kong: "", //单选框label隐藏
      tableAttr: [
        {
          label: this.$i18n.t("label.file.num"), //数量
          prop: "number",
          type: "number",
          editable: false,
          width: "calc(100% / 7)",
        },
        {
          label: this.$i18n.t("label.product.code"), //产品代码
          prop: "code",
          type: "text",
          editable: false,
          width: "calc(100% / 7)",
        },
        {
          label: this.$i18n.t("label.product.name"), //产品名称
          prop: "name",
          type: "text",
          editable: false,
          width: "calc(100% / 7)",
        },
        {
          label: this.$i18n.t("label.product.description"), //产品描述
          prop: "describe",
          type: "text",
          editable: false,
          width: "calc(100% / 7)",
        },
        {
          label: this.$i18n.t("label.partnerCloud.orderingPlatform.unitPrice"), //单价
          prop: "price",
          type: "text",
          editable: false,
          width: "calc(100% / 7)",
        },
      ],
      productList: null,
      productListChildren: null,
      isFilter: false,
      quotatioTable: "",
      navList: [],
      isNavbar: false,
      radio: "1",
      cpradio: "",
      projectForm: {
        dm: "",
        name: "",
        region: "",
      },
      checkAll: false,
      showIndex: true, //序号
      radioCheck: false, //单选框
      productId: "",
      requireChecked: "", //编辑框
      featuree: null,
      isactive: "",
      isactivee: "",
      navId: "", //导航栏当前点击id
      editLineId: "",
      editField: "",
      controlArr: [],
      quantitys: "",
      editId: "",
      childProduct: "",
      //报价行id
      groupId: "",
      optionListArr: [],
      productTitle: "",
      navName: "捆绑包",
      lengthId: "",
      isProduct: false, //是否有产品选项
      ischecked: false, //是否勾选
      quotionData: [],
      productoption: "", //产品选项
      parentId: "", //父产品id
      parentListprice: "", //父产品价格
      parentQuantity: "", //父产品数量
      parentProduct: "", //产品选项
      quotelineid: this.$route.query.quotelineid, //报价行id
      constraintList: [], //约束数组
      editLineIndex: "",
    };
  },
  watch: {
    carConfig: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.isNav();
        }
      },
    },
    editLineId() {
      // this.editLineId = this.editLineId;
    },
    radio() {
      // 
    },
    // radio(nval, oval, list) {
    //   let newarr = [];
    //   this.controlArr.forEach((item) => {
    //     if(item.radioCheck===true){
    //      if (nval === item.id) {
    //       // 
    //       this.optionListArr.push({
    //         product: item.optionalsku,
    //         productoption: item.optionid,
    //         quantity: item.quantity,
    //       });
    //       for (var i = 0; i < this.optionListArr.length; i++) {
    //         if (item.id === this.optionListArr[i].productoption) {
    //           newarr.push(this.optionListArr[i].productoption);
    //           if (
    //             newarr.length > 1 &&
    //             item.optionid == this.optionListArr[i].productoption
    //           ) {
    //             this.optionListArr.splice(i, 1);
    //           }
    //         }
    //         // 
    //       }
    //     }else{
    //       
    //     }
    //     }
    //   });
    // },
  },
  created() {
    this.init();
    this.optionConstraint();
    this.parentList();
  },
  mounted() {},
  beforeDestroy(){
    this.$bus.$off("parentData", this.ebFn);
  },
  methods: {
    ebFn(data) {
        if (data !== "" || data !== undefined || data !== null) {
          data.forEach((item) => {
            //切换保存不同数据
            if (this.productId === item.product) {
              this.parentId = item.id;
              this.parentListprice = item.listprice;
              this.parentQuantity = item.quantity;
              this.parentProduct = item.product;
            }
          });
        }
      },
    init() {
      this.isNav();
    },
    optionConstraint() {
      quotationApi
        .optionConstraint({ productid: this.productId })
        .then((res) => {
          this.constraintList = res.data;
        });
    },
    //接收父产品参数
    parentList() {
      this.$bus.$on("parentData", this.ebFn);
    },
    checkLine(checked, list) {
      list.isCheck = true;
      if (checked) {
        if (
          this.constraintList !== "" ||
          this.constraintList !== null ||
          this.this.constraintList !== undefined
        ) {
          this.constraintList.forEach((constType) => {
            //互斥关系（双向互斥关系）如果用户选择了约束产品选项，则受约束产品选项默认不可选，复选框为灰色
            if (constType.type === "互斥") {
              if (constType.constrainedoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainingoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", true);
                  }
                });
              }
              if (constType.constrainingoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", true);
                  }
                });
              }
            }
            //依赖关系（单向关系）：购买约束产品选项，受约束产品选项才可以勾选。若未购买约束产品选项，则受约束产品选项复选框为灰色，不可勾选。
            if (constType.type === "依赖") {
              if (constType.constrainingoption === list.optionid) {
                list.constraintClick = true;
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    if (list.constraintClick) {
                      item.selectedd = false;
                    }
                  }
                });
              }
            }
          });
        }
        this.ischecked = true;
        let idArr = [];
        let listidArr = [];
        listidArr.push(list.id);
        this.optionListArr.forEach((newlist) => {
          idArr.push(newlist.productoption);
        });
        //勾选状态保存数组如果没有该数据添加该数据至保存数组
        for (var i = 0; i < listidArr.length; i++) {
          if (idArr.indexOf(listidArr[i]) == -1) {
            this.optionListArr.push({
                product: list.optionalsku,
                productoption: list.optionid,
                quantitysetup: list.quantity,
            });
          }
        }
      } else {
        if (
          this.constraintList !== "" ||
          this.constraintList !== null ||
          this.this.constraintList !== undefined
        ) {
          this.constraintList.forEach((constType) => {
            //互斥关系（双向互斥关系）如果用户选择了约束产品选项，则受约束产品选项默认不可选，复选框为灰色
            if (constType.type === "互斥") {
              if (constType.constrainedoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainingoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", false);
                  }
                });
              }
              if (constType.constrainingoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    this.$delete(item, "selectedd");
                    this.$set(item, "selectedd", false);
                  }
                });
              }
            }
            if (constType.type === "依赖") {
              if (constType.constrainingoption === list.optionid) {
                this.controlArr.forEach((item) => {
                  if (constType.constrainedoption === item.optionid) {
                    if (list.constraintClick) {
                      this.$delete(item, "selectedd");
                      this.$delete(item, "isCheck");
                      this.$set(item, "selectedd", true);
                      this.$set(item, "isCheck", false);
                      //取消勾选不保存该数据
                      for (var i = 0; i < this.optionListArr.length; i++) {
                        if (item.id == this.optionListArr[i].productoption) {
                          this.optionListArr.splice(i, 1);
                        }
                      }
                    }
                  }
                });
              }
            }
          });
        }
        list.isCheck = false;
        this.ischecked = false;
        //取消勾选不保存该数据
        for (var j = 0; j < this.optionListArr.length; j++) {
          if (list.id == this.optionListArr[j].productoption) {
            this.optionListArr.splice(j, 1);
          }
        }
      }
      // })
    },
    //清除条件
    clearBtn() {
      this.projectForm = {
        dm: "",
        name: "",
        region: "",
      };
      this.showProductConfigList();
      this.isFilter = false;
    },
    //保存
    async configSave() {
      this.quotionData.push({
        product: this.carConfig.id,
        quantity: "1",
        childproduct: JSON.stringify(this.optionListArr),
      });
      let data = {};
      data = {
        pricebook: this.pricebookid,
        productJson: JSON.stringify(this.quotionData),
      };
      this.navList.forEach((item) => {
        //改是否保存状态
        if (this.productId === item.id) {
          item.isEdit = true;
        }
      });
      let result = await SaveShoppingCart(data);
      if (result.result) {
        this.$message.success(this.$i18n.t("vue_label_notice_add_success")); //添加成功
        this.$emit("closeDialog");
      }
    },
    //报价行表格内联编辑
    edit(groupIndex, index, prop) {
      this.editGroupIndex = groupIndex;
      this.editLineIndex = index;
      this.editField = prop;
    },
    //修改列表值
    //修改列表值
    modify(groupIndex, lineIndex, prop, inputNumber) {
      //无分组
      this.modifyQuoteLine(this.controlArr, lineIndex, prop, inputNumber);
    },
    //递归查找数据进行修改
    modifyQuoteLine(controlArr, lineIndex, prop, inputNumber) {
      controlArr.forEach((productLine) => {
        if (productLine.index === lineIndex) {
          this.editId = productLine.id;
          productLine[prop] = inputNumber;
          this.quantitys = productLine[prop];
          if (prop !== "discount" && prop !== "additionaldiscount") {
            this.$set(productLine, `${prop}edit`, "true");
          } else {
            this.$set(productLine, `additionaldiscountedit`, "true");
          }
          return;
        }
      });
      //改变原来配置项的值
      this.optionListArr.forEach((newList) => {
        if (newList.productoption === this.editId) {
          newList.quantity = this.quantitys.toString();
        }
      });
    },
    //tab切换
    conTab(item) {
      this.featuree = item.feature;
      this.isactive = item.id;
    },
    isNav() {
      this.productId = this.carConfig.id;
      this.productTitle = "";
      let prrId = this.productId.split(",");
      //产品id>1时展示导航栏
      if (prrId.length > 1) {
        let params = {
          productid: this.productId,
          currency: this.carConfig.currency,
          // quoteid: this.carConfig.id,
          pricebookid: this.pricebookid,
        };
        quotationApi.showPricebookItems(params).then((res) => {
          //为空的话不展示侧边栏
          if (res.data == "" || res.data == undefined || res.data == null) {
            this.isNavbar = false;
          } else {
            this.isNavbar = true;
            res.data.forEach((item) => {
              this.navList.push({
                id: item.productid,
                title: item.productname,
                isEdit: false,
              });
            });
            if (res.data == "" || res.data == null || res.data == undefined) {
              //默认展示第一个产品
              this.productTitle = res.data[0].productname;
              this.productId = res.data[0].productid;
              this.lengthId = prrId.length;
              this.isProduct = true;
            } else {
              this.productTitle = res.data[0].productname;
              this.productId = res.data[0].productid;
              this.lengthId = prrId.length;
              this.isProduct = false;
            }
            this.showProductConfigList();
          }
        });
      } else {
        this.isNavbar = false;
        this.productId = this.carConfig.id;
        let params = {
          productid: this.productId,
          // quoteid: this.carConfig.id,
          currency: this.carConfig.currency,
          pricebookid: this.pricebookid,
        };
        //默认展示第一个产品
        quotationApi.showPricebookItems(params).then((res) => {
          if (res.data.length > 0) {
            //默认展示第一个产品
            this.productTitle = res.data[0].productname;
            this.productId = res.data[0].productid;
            this.lengthId = prrId.length;
          }
        });
        this.lengthId = prrId.length;
        this.showProductConfigList();
      }
    },
    //展示产品列表
    showProductConfigList() {
      this.loading = true;
      this.parentList();
      let params = {
        productid: this.productId,
        currency: this.carConfig.currency,
        pricebookid: this.pricebookid,
        featurename: this.projectForm.region,
        cpdm: this.projectForm.dm,
        productname: this.projectForm.name,
        quotelineid: this.quotelineid,
      };
      quotationApi.showProductConfigList(params).then((res) => {
        this.loading = false;
        //设置表头信息
        res.data.tableLabel.forEach((title) => {
          title.prop = title.apiname;
          title.type = title.fieldtype;
          let titleId = title.id;
          title.id = titleId;
          title.width = "calc((100% - 290px) / 5)";
          if (title.prop === "quantity") {
            title.editable = true;
          }
        });
        this.tableAttr = res.data.tableLabel;
        this.productList = res.data.result;
        //设置默认显示数据
        this.productList.forEach((item) => {
          //默认展示第一项
          // this.productTitle=item.pricebookitemname
          if (
            item.functionctategory == "" ||
            item.functionctategory == undefined ||
            item.functionctategory == null
          ) {
            this.isProduct = true;
          } else {
            this.isProduct = false;
            this.featuree = item.functionctategory[0].feature;
            this.isactive = item.functionctategory[0].id;
            item.functionctategory.forEach((ite) => {
              if (ite.feature) {
                this.isProduct = false;
                ite.feature.checkAll = false;
                ite.feature.forEach((feature) => {
                  feature.option.forEach((option, index) => {
                    option.index = index;
                    this.controlArr.push(option);
                    option.id = option.optionid;
                    //产品选项最大数量(MaxOptionCount)和产品选项最小数量(MinOptionCount)如果值均为1时，该功能下的产品选项前按钮均为单选框。
                    if (
                      option.maxoptioncount == "1" &&
                      option.minoptioncount == "1"
                    ) {
                      this.radio = option.id;
                      //单选显示隐藏
                      option.radioCheck = true;
                      option.check = false;
                      //全选显示隐藏
                      feature.ischeckAll = false;
                    } else {
                      //单选显示隐藏
                      option.radioCheck = false;
                      option.check = true;
                      //全选显示隐藏
                      feature.ischeckAll = true;
                    }
                    //如果产品选项数量字段有值，则取该值；如果没有值，则默认数量为1。
                    if (
                      option.quantity === "" ||
                      option.quantity === undefined ||
                      option.quantity === null
                    ) {
                      option.quantity = "1.00";
                    } else {
                      let quantity = option.quantity;
                      option.quantity = quantity;
                    }
                    //产品选项-单价(UnitPrice)【币种（12, 2）】。如果产品选项单价字段有值，则取该值；如果没有值，则取该产品价格手册条目的价目表价格。
                    if (
                      option.unitprice === "" ||
                      option.unitprice === undefined ||
                      option.unitprice === null
                    ) {
                      option.unitprice = option.listprice;
                    }
                    //如果是必须配置的默认置灰并勾选
                    if (option.required === "true") {
                      option.selectedd = true;
                      option.isCheck = true;
                    } else {
                      option.selectedd = false;
                      option.isCheck = false;
                    }
                    if (
                      option.selected === "true" ||
                      option.required === "true"
                    ) {
                      option.isCheck = true;
                    } else {
                      option.isCheck = false;
                    }
                    //必须配置的子产品
                    if (option.isCheck === true) {
                      this.optionListArr.push({
                          product: option.optionalsku,
                          productoption: option.optionid,
                          quantitysetup: option.quantity,
                      });
                      
                    }
                    //如果是编辑报价行跳过来的数量使用编辑报价行带来的数量
                    if (option.savetolinequantity) {
                      option.quantity = option.savetolinequantity + ".00";
                    }
                    //如果是编辑报价行跳过来的默认选中
                    if (option.chosenproductoption) {
                      option.isCheck = true;
                    }
                    ///是依赖关系的话默认把受约束产品置灰
                    if (
                      this.constraintList !== "" ||
                      this.constraintList !== undefined ||
                      this.constraintList !== null
                    ) {
                      this.constraintList.forEach((constType) => {
                        if (constType.type === "依赖") {
                          if (constType.constrainedoption === option.optionid) {
                            option.selectedd = true;
                          }
                        }
                      });
                    }
                  });
                });
              } else {
                this.isProduct = true;
              }
            });
          }
        });
      });
    },
    handleSelectionChange() {},
    //左侧选项卡
    jump(index, item) {
      //用点击前数据进行判断
      this.navList.forEach((ite) => {
        if (this.productId === ite.id) {
          //如果没有配置必须配置完再切换
          if (this.productId !== item.id) {
            if (ite.isEdit === true) {
              this.productId = item.id;
              this.productTitle = item.title;
              this.isactivee = index;
              this.showProductConfigList();
            } else {
              this.$confirm(
                "是否保存当前配置的产品，并进行下一个产品的配置？",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.configSave();
                  this.productId = item.id;
                  this.productTitle = item.title;
                  this.isactivee = index;
                  this.$message({
                    type: "success",
                    message: "保存成功!",
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            }
          }
        }
      });
    },
    cancelConfig() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.car-config-dialog {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #fff;
    .price_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
      border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0px 0px;
      .tit {
        line-height: 1.2;
        div {
          width: 150px;
          margin-top: 3px;
          p:first-child {
            font-size: 12px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #080707;
            font-weight: 600;
          }
        }
      }
      .search {
        float: right;
        width: 278px;
        margin-right: 10px;
        .btn_group {
          display: flex;
          justify-content: flex-end;
          .shaixuan {
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 3px;
            cursor: pointer;
            margin-right: 10px;
            img {
              margin-left: -5px;
              margin-top: -1px;
              width: 16px;
            }
          }
          ::v-deep .el-button--small,
          .el-button--small.is-round {
            padding: 8px 17px !important;
            color: #006dcc;
          }
          ::v-deep .el-button:hover {
            background: #fff;
            border: 1px solid #dedcda;
            color: #006dcc;
          }
        }
        div {
          position: relative;
          img {
            width: 16px;
            position: absolute;
            top: 7px;
            left: 10px;
          }
        }
      }
      div {
        height: 30px;
        .viewImg {
          margin-right: 10px;
          margin-left: 10px;
          width: 37px;
          float: left;
        }
        p:first-child {
          width: 100%;
          margin-top: -2px;
          margin-bottom: 0;
        }
        p:nth-child(2) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }

      .icon {
        color: #888888;
        position: relative;
        top: -32px;
        margin: 0 12px;
        cursor: pointer;
      }
    }
    .wrap {
      width: 100%;
      height: 91%;
      display: flex;
      .navigationss {
        height: 100%;
      }
      .configTable {
        width: 100%;
        height: 100%;
        overflow: auto;
        background: #fff;
        .quotation-table {
          width: 100%;
          background: #fff;
          // border: 1px solid #dedcda;
          // border-bottom: 1px solid #dedcda;
          border-radius: 3px;
          .goods {
            width: 100%;
            // height: 76.9px;
            border-bottom: 1px solid #dddbda;
            // margin-bottom: 15.1px;
            .jgsc {
              margin-left: 10px;
              font-family: SourceHanSansCN-Regular;
              font-size: 16px;
              color: #080707;
              letter-spacing: 0;
              line-height: 52px;
            }
            .productTab {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .configTab {
                width: 100%;
                border-bottom: 1px solid #dedcda;
                span {
                  cursor: pointer;
                  font-family: SourceHanSansCN-Bold;
                  font-size: 12px;
                  color: #080707;
                  letter-spacing: 0;
                  line-height: 30px;
                  margin-left: 40px;
                }
                span:nth-child(1) {
                  margin-left: 10px;
                }

                .active {
                  font-family: SourceHanSansCN-Bold;
                  font-size: 12px;
                  color: #006dcc;
                  letter-spacing: 0;
                  line-height: 30px;
                  border-bottom: 2px solid #006dcc;
                }
              }
              // .configTab:nth-child(1) {
              //   margin-right: 40px;
              // }
            }
          }
          .Navtable {
            border: 1px solid #dddbda;
            border-radius: 0 0 3px 3px;
            .el-radio {
              margin: 0;
              padding-left: 10px;
            }
          }
          .twoTitle {
            padding: 10px;
            width: 100%;
          }
          .goodstypee {
            width: 99%;
            height: 41px;
            display: block;
            margin: 10px;
            background: #fafaf9;
            border: 1px solid #c8c6c6;
            border-radius: 3px 3px 0 0;
            font-family: SourceHanSansCN-Regular;
            padding-left: 10px;
            font-size: 16px;
            color: #080707;
            letter-spacing: 0;
            line-height: 41px;
          }
          .goodstype {
            width: 100%;
            height: 41px;
            display: block;
            background: #fafaf9;
            border: 1px solid #c8c6c6;
            border-radius: 3px 3px 0 0;
            font-family: SourceHanSansCN-Regular;
            padding-left: 10px;
            font-size: 16px;
            border-bottom: none;
            color: #080707;
            letter-spacing: 0;
            line-height: 41px;
          }
          ul {
            margin-bottom: 0;
            display: flex;
            background: #fafaf9;
            li {
              padding: 0 10px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #3e3e3c;
              border-left: 1px solid #dedcda;
            }

            .tootip-span {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
            }

            .checkbox-li {
              width: 30px;
              padding: 0;
              text-align: center;
              border: none;
            }

            .number-li {
              width: 40px;
              padding: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
